<template>
    <div class="page">
            <el-container class="page-home">
                <el-header>
                        <span class="kongzhitai"> {{htmlname}}</span>
                        <!-- <div  class="debuglogin">
                            <img class="logo" :src="require('../assets/唐山吉唐智能科技有限公司_03.png')" alt="解析失败" />

                            <span>当前用户:{{this.role}}</span>

                            <img class="rotatelogo" :src="require('../assets/唐山吉唐智能科技有限公司_06.png')" alt="解析失败" />

                        </div> -->

                        <!-- <span @click="todeletetoken" class="debuglogin">注销</span> -->

                        <el-dropdown class="floatright"  @command="handleCommand">
                            <span class="el-dropdown-link">
                                <img class="logo" :src="require('../assets/唐山吉唐智能科技有限公司_03.png')" alt="解析失败" />
                                <span>当前用户:{{this.role}}</span>
                                <img class="rotatelogo" :src="require('../assets/唐山吉唐智能科技有限公司_06.png')" alt="解析失败" />
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="a" style="width:100px;text-align:center">退出系统</el-dropdown-item>
                                <!-- <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                <el-dropdown-item divided>蚵仔煎</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                        

                </el-header>

                <el-container>
                    <el-aside width="200px">
                        <el-menu
                            background-color="#343F52"
                            text-color="#FFFFFF"
                            router
                            :default-active="this.$route.path"
                        >
                            <!-- <el-menu-item index="/statistics">数据统计</el-menu-item> -->
                            <el-menu-item index="/statistics">
                                <!-- <router-link to="/statistics" tag="span">数据统计</router-link> -->
                                数据统计
                            </el-menu-item>
                            <el-menu-item  index="/user">
                                <!-- <router-link to="/user" tag="span">用户管理</router-link> -->

                                用户管理
                            </el-menu-item>
                            <el-menu-item index="/customer">
                                <!-- <router-link to="/customer" tag="span">客户档案</router-link> -->

                                客户档案
                            </el-menu-item>
                            <el-menu-item index="/customermap">
                                客户地图
                            </el-menu-item>
                            <el-menu-item index="/principal">
                                施工负责人
                            </el-menu-item>
                            <el-menu-item index="/task">
                                任务管理

                            </el-menu-item>
                            <el-menu-item index="/machine">
                                设备管理
                            </el-menu-item>
                            <el-menu-item index="/contact">
                                合同管理
                            </el-menu-item>
                            <el-menu-item  index="/makeproduct">
                                生产管理
                            </el-menu-item>
                            <el-menu-item  index="/daynum">
                                生产管理(按日)
                            </el-menu-item>
                            <el-menu-item  index="/zhishiku">
                                知识库管理
                            </el-menu-item>
                            <el-menu-item  index="/baoxiu">
                                客户报修
                            </el-menu-item>
    
                        </el-menu>
    
                    </el-aside>

                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-container>
    </div>
</template>
<script>
export default({
    data() {
        return {
            role:localStorage.getItem('user_role'),
            // active1: '/customer'
            htmlname:'唐山吉唐智能环保系统'
        }
    },
    mounted() {
        // console.log(this.$route.name,'路由')
        // this.active1 = this.$route.name
        this.getname()
    },
    methods: {
        todeletetoken() {
            console.log('注销登录')
            localStorage.removeItem('token')
            this.$router.push({name: 'login'})
        },
        handleCommand(e){
            if(e == 'a') {
                this.todeletetoken()
            }
        },
        getname() {
            // console.log(window.location.host.split('.'),'名字')
            if(window.location.host.split('.')[0] == 'www') {
                this.htmlname = '唐山吉唐智能环保系统'
            }else if(window.location.host.split('.')[0] == 'xm') {
                this.htmlname = '厦门洁莱环境科技有限公司'
            }else if(window.location.host.split('.')[0] == 'bj'){
                this.htmlname = '北京双洁环保科技有限公司'
            }else if(window.location.host.split('.')[0] == 'cd') {
                this.htmlname = '承德东万环保科技有限公司'
            }else if(window.location.host.split('.')[0] == 'sjz') {
                this.htmlname = '石家庄超洁环境科技有限公司'
            }else if(window.location.host.split('.')[0] == 'nb') {
                this.htmlname = '宁波众洁环境科技有限公司'
            }else if(window.location.host.split('.')[0] == 'sd') {
                this.htmlname = '山东顺阳环保科技有限公司'
            }else if(window.location.host.split('.')[0] == 'xa') {
                this.htmlname = '西安赛洁环境科技有限公司'

            }
            else {
                this.htmlname = '唐山吉唐智能环保系统'
            }
        },
    }
})
</script>
<style  lang="scss" scoped>
.page {
    height: 100%;
}
.page-home{
   height: 100%;
}
.el-container {
    height: 100%;
}
  .el-header, .el-footer {
    background-color: #005DB2;
    color: #fff;
    // text-align: center;
    line-height: 60px;
    // text-align: right;
    padding-right: 25px;
    padding-left: 0;
  }
  
  .el-aside {
    background-color: #343F52;
    color: #fff;
    text-align: center;
    // line-height: 200px;
    // padding-left: 40px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }
  .debuglogin {
    // padding:18px;
    padding-right:15px;
    padding-left:15px;

    cursor:pointer;
    float:right;
    font-size:14px
  }
  .kongzhitai {
    color: #FEFEFF;
    font-size:20px;
    padding-left: 45px;
  }
  
//   .el-aside> ul >:nth-child(1) {

//     background-color: #2A3242;
//   }
  
.logo {
    width: 18px;
    position: relative;
    top: 3px;
    left: -5px;
}
.rotatelogo {
    position: relative;
    left: 3px;
    height:12px;
    top: 2px;
}
.floatright {
    float:right;
}
.el-dropdown-link {
    color: white;
    height: 100%;
    display: inline-block;
}


</style>
